<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="id-content">
            <div>
                <img src="../../assets/images/idcard.png" alt="">
                <span>点击上传人面像</span>
            </div>
            <div>
                <img src="../../assets/images/idcard.png" alt="">
                <span>点击上传国徽面</span>
            </div>
            <div>
                <img src="../../assets/images/idcard.png" alt="">
                <span>点击上传手持身份正面照</span>
            </div>
        </div>
        <div class="submitBtn">
            <button>确定</button>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        height: 100vh;
        .id-content{
            width: 100%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            div{
                width: 60%;
                height: 140px;
                display: flex;
                justify-content: space-around;
                background-color: #eee;
                align-items: center;
                margin: 10px;
                img{
                    margin: 0 6px;
                    width: 60px;
                    height: 60px;
                }
                span{
                    font-size: 14px;
                    display: inline-block;
                    width: 100px;
                }
            }
        }
        .submitBtn{
            width: 100%;
            text-align: center;
            height: 40px;
            button{
                height: 32px;
                width: 60%;
                border: none;
                background-color: #d25555;
                color: #fff;
                border-radius: 6px;
                font-size: 16px;
            }
        }
    }
</style>